import React, { useState, useEffect, useRef } from "react";
import Aos from "aos";
// import "aos/dist/aos.css";
import loading from "../images/loading.gif";
import Header from "./layouts/Header";

export const checkPageMenu = (imageNumber, page) => {
  if (page === "Reservation" && imageNumber < 89) {
    return true;
  } else if (
    page === "Nos evenements" &&
    imageNumber >= 89 &&
    imageNumber < 134
  ) {
    return true;
  } else if (page === "Carte" && imageNumber >= 134 && imageNumber < 174) {
    return true;
  } else if (page === "Visite 360" && imageNumber >= 174 && imageNumber < 223) {
    return true;
  } else if (page === "Contactez-nous" && imageNumber >= 223) {
    return true;
  } else {
    return false;
  }
};

const checkPage = (imageNumber, page) => {
  if (page === "Reservation" && imageNumber >= 47 && imageNumber < 89) {
    return false;
  } else if (
    page === "Nos evenements" &&
    imageNumber >= 89 &&
    imageNumber < 134
  ) {
    return false;
  } else if (page === "Carte" && imageNumber >= 134 && imageNumber < 174) {
    return false;
  } else if (page === "Visite 360" && imageNumber >= 174 && imageNumber < 223) {
    return false;
  } else if (page === "Contactez-nous" && imageNumber >= 223) {
    return false;
  } else {
    return true;
  }
};

const MenuItems = (imageNumber) => {
  return (
    <div className="flex flex-row items-center md:justify-center justify-start w-full md:px-3 mb-5 md:mt-2 mt-10">
      <div className="flex flex-col  md:justify-center justify-start items-center left-0 right-0 md:w-full w-screen ">
        <div
          onClick={() => {
            const element = document.getElementById("reservation");
            if (element) {
              element.scrollIntoView({ behavior: "smooth" });
            }
          }}
          className={` md:text-xl text-xl font-bold hover:text-[#9e804f]  cursor-pointer md:mb-2  ${
            checkPageMenu(imageNumber.imageNumber, "Reservation")
              ? "text-[#9e804f] "
              : "text-white"
          }`}
          href="/#reservation"
          style={{
            textShadow: checkPageMenu(imageNumber.imageNumber, "Reservation")
              ? "#9e804f 0px 0px 2px "
              : "",
          }}
        >
          Reservation
        </div>
        {/* <div className="text-sm">|</div> */}
        <div
          onClick={() => {
            const element = document.getElementById("evenements");
            if (element) {
              element.scrollIntoView({ behavior: "smooth" });
            }
          }}
          className={` md:text-xl text-xl font-bold hover:text-[#9e804f]  cursor-pointer md:mb-2  ${
            checkPageMenu(imageNumber.imageNumber, "Nos evenements")
              ? "text-[#9e804f] "
              : "text-white"
          }`}
          style={{
            textShadow: checkPageMenu(imageNumber.imageNumber, "Nos evenements")
              ? "#9e804f 0px 0px 2px "
              : "",
          }}
          href="/#evenements"
        >
          Nos evenements
        </div>
        {/* <div className="text-sm">|</div> */}
        <div
          onClick={() => {
            const element = document.getElementById("cartes");
            if (element) {
              element.scrollIntoView({ behavior: "smooth" });
            }
          }}
          className={` md:text-xl text-xl font-bold hover:text-[#9e804f]  cursor-pointer md:mb-2  ${
            checkPageMenu(imageNumber.imageNumber, "Carte")
              ? "text-[#9e804f] "
              : "text-white"
          }`}
          style={{
            textShadow: checkPageMenu(imageNumber.imageNumber, "Carte")
              ? "#9e804f 0px 0px 2px "
              : "",
          }}
          href="/#cartes"
        >
          Carte
        </div>
        {/* <div className="text-sm">|</div> */}
        <div
          onClick={() => {
            const element = document.getElementById("visites");
            if (element) {
              element.scrollIntoView({ behavior: "smooth" });
            }
          }}
          className={` md:text-xl text-xl font-bold hover:text-[#9e804f]  cursor-pointer md:mb-2  ${
            checkPageMenu(imageNumber.imageNumber, "Visite 360")
              ? "text-[#9e804f] "
              : "text-white"
          }`}
          style={{
            textShadow: checkPageMenu(imageNumber.imageNumber, "Visite 360")
              ? "#9e804f 0px 0px 2px "
              : "",
          }}
          href="/#visites"
        >
          Visite 360
        </div>
        {/* <div className="text-sm">|</div> */}
        <div
          onClick={() => {
            const element = document.getElementById("contact-us");
            if (element) {
              element.scrollIntoView({ behavior: "smooth" });
            }
          }}
          className={` md:text-xl text-xl font-bold hover:text-[#9e804f]  cursor-pointer md:mb-2  ${
            checkPageMenu(imageNumber.imageNumber, "Contactez-nous")
              ? "text-[#9e804f] "
              : "text-white"
          }`}
          href="/#contact-us"
          style={{
            textShadow: checkPageMenu(imageNumber.imageNumber, "Contactez-nous")
              ? "#9e804f 0px 0px 1px "
              : "",
          }}
        >
          Contactez-nous
        </div>
      </div>
    </div>
  );
};

const debounce = (func, delay) => {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, args), delay);
  };
};

const imageNames = Array.from(
  { length: 264 },

  (_, index) =>
    `https://epiceventsstatic.s3.eu-west-3.amazonaws.com/animation-eden/web/frame${(
      index + 1
    )
      .toString()
      .padStart(4, "0")}.jpg`
);

// const imageNames = Array.from(
//   { length: 264 },

//   (_, index) =>
//     `https://epiceventsstatic.s3.eu-west-3.amazonaws.com/animation-eden/imgweb/Frame${(
//       index + 1
//     )
//       .toString()
//       .padStart(4, "0")}.jpg`
// );

const imageNamesMobile = Array.from(
  { length: 264 },

  (_, index) =>
    `https://epiceventsstatic.s3.eu-west-3.amazonaws.com/animation-eden/mobileimg/frame${(
      index + 1
    )
      .toString()
      .padStart(4, "0")}.jpg`
);
// const imageNamesMobile = Array.from(
//   { length: 264 },

//   (_, index) =>
//     `https://epiceventsstatic.s3.eu-west-3.amazonaws.com/animation-eden/imgmobile/Frame${(
//       index + 1
//     )
//       .toString()
//       .padStart(4, "0")}.jpg`
// );

function HomeScreen() {
  const [isLoading, setIsLoading] = useState(true);
  const [isStart, setIsStart] = useState(true);
  const [percent, setPercent] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [totalImages, setTotalImages] = useState(264);

  const canvasRef = useRef(null);

  const [wSize, setWSize] = useState(window.innerWidth);
  const [hSize, setHSize] = useState(window.innerHeight);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [isMounted, setIsMounted] = useState(true); // Flag to check if component is mounted

  const [imagesLoading, setImagesLoading] = useState([]);
  const [isScrool, setIsScrool] = useState(true);
  const [canvasContext, setCanvasContext] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);

  useEffect(() => {
    if (imagesLoaded) {
      setTimeout(() => {
        setIsLoading(false);
      }, 10);
    }
  }, [imagesLoaded]);

  useEffect(() => {
    // Load images before showing the page

    const promises = imageNames.map((imageName, index) => {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          const img = new Image();
          img.onload = () => {
            resolve();
            if (!imagesLoading.includes(imageName)) {
              imagesLoading.push(imageName);
              setPercent(index + 1);
            }
          };
          img.onerror = () => reject();
          img.src = imageName;
        }, 10 * (index + 1));
      });
    });

    Promise.all(promises)
      .then(() => {
        if (isMounted) {
          setImagesLoaded(true);
        }
      })

      .catch((error) => {
        console.error("Failed to load images:", error);
      });

    // Cleanup
    return () => {
      setIsMounted(false); // Set flag to false when unmounting
    };
  }, [isMounted, totalImages, imagesLoading, imageNames]);

  useEffect(() => {
    // Load images before showing the page

    const promises = imageNamesMobile.map((imageName, index) => {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          const img = new Image();
          img.onload = () => {
            resolve();
            if (!imagesLoading.includes(imageName)) {
              imagesLoading.push(imageName);
              setPercent(index + 1);
            }
          };
          img.onerror = () => reject();
          img.src = imageName;
        }, 10 * (index + 1));
      });
    });

    Promise.all(promises)
      .then(() => {
        if (isMounted) {
          setImagesLoaded(true);
        }
      })

      .catch((error) => {
        console.error("Failed to load images:", error);
      });

    // Cleanup
    return () => {
      setIsMounted(false); // Set flag to false when unmounting
    };
  }, [isMounted, totalImages, imagesLoading, imageNamesMobile]);

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // useEffect(() => {
  //   const handleLazyLoad = () => {
  //     const windowHeight = window.innerHeight;
  //     const imagesToShow = [];

  //     imageNames.forEach((imageName, index) => {
  //       const imageElement = document.getElementById(`image-${index}`);
  //       if (imageElement) {
  //         const rect = imageElement.getBoundingClientRect();
  //         if (rect.top >= -100 && rect.bottom <= windowHeight + 100) {
  //           imagesToShow.push(index);
  //         }
  //       }
  //     });
  //   };

  //   const handleLazyLoadMobile = () => {
  //     const windowHeight = window.innerHeight;
  //     const imagesToShow = [];

  //     imageNamesMobile.forEach((imageName, index) => {
  //       const imageElement = document.getElementById(`image-${index}`);
  //       if (imageElement) {
  //         const rect = imageElement.getBoundingClientRect();
  //         if (rect.top >= -100 && rect.bottom <= windowHeight + 100) {
  //           imagesToShow.push(index);
  //         }
  //       }
  //     });
  //   };

  //   handleLazyLoad(); // Initial load
  //   window.addEventListener("scroll", handleLazyLoad);

  //   return () => {
  //     window.removeEventListener("scroll", handleLazyLoad);
  //   };
  // }, [imageNames]);

  // Generate the image URLs based on the scroll position
  const imageNumber =
    Math.floor(scrollPosition / 15) + 1 > totalImages
      ? totalImages
      : Math.floor(scrollPosition / 15) + 1; // Adjust the divisor for speed

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const context = canvas.getContext("2d");
      setCanvasContext(context);
    }
  }, []);
  useEffect(() => {
    if (!canvasContext || !currentImage) return;

    canvasContext.clearRect(
      0,
      0,
      canvasRef.current.width,
      canvasRef.current.height
    );
    canvasContext.drawImage(
      currentImage,
      0,
      0,
      canvasRef.current.width,
      canvasRef.current.height
    );
  }, [canvasContext, currentImage]);

  useEffect(() => {
    const renderImage = (index) => {
      const canvas = canvasRef.current;
      if (!canvasContext || !canvas) return;

      const img = new Image();
      img.onload = () => {
        setCurrentImage(img);

        canvasContext.clearRect(0, 0, canvas.width, canvas.height);
        canvasContext.drawImage(img, 0, 0, canvas.width, canvas.height);
      };
      img.src =
        window.innerWidth <= 768 ? imageNamesMobile[index] : imageNames[index];
    };

    renderImage(imageNumber - 1);
  }, [
    imageNumber,
    canvasContext,
    imageNames,
    imageNamesMobile,
    isLoading,
    isStart,
    isScrool,
  ]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const context = canvas.getContext("2d");

      const resizeCanvas = () => {
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
        // You might want to redraw the content on canvas here
        // For now, let's just clear it
        context.clearRect(0, 0, canvas.width, canvas.height);
      };

      resizeCanvas(); // Initial resize

      const handleResize = () => {
        // Delay resizing to improve performance
        clearTimeout(window.resizeTimer);
        // window.resizeTimer = setTimeout(() => {
        // resizeCanvas();
        // }, 250);
      };
      // handleResize();
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  return (
    <div className="relative scroll-smooth text-white  w-full h-full  bg-black ">
      <canvas
        className="fixed w-screen h-screen scrolla z-10  bg-black  "
        ref={canvasRef}
        width={window.innerWidth}
        height={window.innerHeight}
        style={{
          display: isLoading ? "none" : "block",
        }}
      />
      {isStart ? (
        <div
          className=" w-screen h-screen fixed z-50"
          style={{
            backgroundImage:
              'url("https://epiceventsstatic.s3.eu-west-3.amazonaws.com/animation-eden/web/frame-0003-min.jpg")',
            backgroundPosition: "top",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          {isLoading ? (
            <div className=" relative flex flex-col items-center justify-end  text-center w-screen h-screen text-white text-2xl ">
              <div className="bg-[#9e804f] h-screen w-screen bg-opacity-50"></div>
              <div className=" absolute h-screen top-0  z-50 c-loader rel x y | js-loader">
                <div
                  className="transition duration-400 ease-in-out mx-auto c-loader__bg x y | js-loader-bg w-2 bg-[#9e804f]"
                  style={{
                    opacity: 1,
                    height: (percent / 264) * 100 + "%",
                  }}
                ></div>
                <div
                  className={`absolute left-0    c-loader__content abs top left x y | js-loader-content`}
                  style={{
                    opacity: 1,
                    visibility: "inherit",
                    top: (percent / 264) * 100 - 3 + "%",
                    left: "-55px",
                    // top: (percent / 256) * 100 + "%",
                  }}
                >
                  <div className=" ml-0.5 c-loader-container x y flex flex-row justify-center items-center">
                    <div className=" c-loader-line z2" style={{ top: "100%" }}>
                      <svg
                        className="c-loader-line-svg"
                        width="113"
                        height="113"
                        viewBox="0 0 113 113"
                        fill="#9e804f"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g filter="url(#filter0_d_266_2906)">
                          <path
                            d="M84.9107 54.1512L58.1117 27.9903C57.328 27.2253 56.0748 27.2328 55.3004 28.0072L27.7947 55.5132C27.0071 56.3008 27.0146 57.5799 27.8114 58.3582L55.2503 85.1592C56.0469 85.9373 57.3257 85.9149 58.0945 85.1094L84.9603 56.9633C85.7169 56.1706 85.6948 54.9167 84.9107 54.1512Z"
                            fill="#9e804f"
                            fillOpacity="0.31"
                            shapeRendering="crispEdges"
                          ></path>
                          <path
                            d="M84.9107 54.1512L58.1117 27.9903C57.328 27.2253 56.0748 27.2328 55.3004 28.0072L27.7947 55.5132C27.0071 56.3008 27.0146 57.5799 27.8114 58.3582L55.2503 85.1592C56.0469 85.9373 57.3257 85.9149 58.0945 85.1094L84.9603 56.9633C85.7169 56.1706 85.6948 54.9167 84.9107 54.1512Z"
                            stroke="#9e804f"
                            strokeLinejoin="round"
                            shapeRendering="crispEdges"
                          ></path>
                        </g>
                        <g filter="url(#filter2_d_266_2906)">
                          <rect
                            x="60.513"
                            y="27.4553"
                            width="41.271"
                            height="41.271"
                            rx="1.75"
                            transform="rotate(53.3715 60.513 27.4553)"
                            stroke="#9e804f"
                            strokeWidth="0.5"
                            shapeRendering="crispEdges"
                          ></rect>
                        </g>
                        <g filter="url(#filter2_d_266_2906)">
                          <rect
                            x="56.0977"
                            y="47.2656"
                            width="12.279"
                            height="12.279"
                            transform="rotate(45 56.0977 47.2656)"
                            fill="white"
                          ></rect>
                        </g>
                        <defs>
                          <filter
                            id="filter0_d_266_2906"
                            x="25.8987"
                            y="26.1111"
                            width="60.9252"
                            height="60.9277"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                          >
                            <feFlood
                              floodOpacity="0"
                              result="BackgroundImageFix"
                            ></feFlood>
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            ></feColorMatrix>
                            <feOffset></feOffset>
                            <feGaussianBlur stdDeviation="0.405138"></feGaussianBlur>
                            <feComposite
                              in2="hardAlpha"
                              operator="out"
                            ></feComposite>
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0.773974 0 0 0 0 0.214858 0 0 0 0 1 0 0 0 1 0"
                            ></feColorMatrix>
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_266_2906"
                            ></feBlend>
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_266_2906"
                              result="shape"
                            ></feBlend>
                          </filter>
                          <filter
                            id="filter1_d_266_2906"
                            x="0.290436"
                            y="0.354401"
                            width="111.946"
                            height="111.946"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                          >
                            <feFlood
                              floodOpacity="0"
                              result="BackgroundImageFix"
                            ></feFlood>
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            ></feColorMatrix>
                            <feOffset></feOffset>
                            <feGaussianBlur stdDeviation="13.7747"></feGaussianBlur>
                            <feComposite
                              in2="hardAlpha"
                              operator="out"
                            ></feComposite>
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0.517647 0 0 0 0 0.737255 0 0 0 0 0.854902 0 0 0 1 0"
                            ></feColorMatrix>
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_266_2906"
                            ></feBlend>
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_266_2906"
                              result="shape"
                            ></feBlend>
                          </filter>
                          <filter
                            id="filter2_d_266_2906"
                            x="40.9338"
                            y="40.7834"
                            width="30.3277"
                            height="30.3297"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                          >
                            <feFlood
                              floodOpacity="0"
                              result="BackgroundImageFix"
                            ></feFlood>
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            ></feColorMatrix>
                            <feOffset></feOffset>
                            <feGaussianBlur stdDeviation="3.24111"></feGaussianBlur>
                            <feComposite
                              in2="hardAlpha"
                              operator="out"
                            ></feComposite>
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0.568826 0 0 0 0 0.394025 0 0 0 0 1 0 0 0 1 0"
                            ></feColorMatrix>
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_266_2906"
                            ></feBlend>
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_266_2906"
                              result="shape"
                            ></feBlend>
                          </filter>
                        </defs>
                      </svg>
                    </div>

                    <div className="c-loader-logo z2"></div>

                    <div
                      className="loader-progress z2 ftext | js-loader-progress text-[darkslategrey] "
                      style={{ top: (100 / 264) * 100 + "%" }}
                    >
                      {((percent / 264) * 100).toFixed(0)}%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="h-screen relative text-center ">
              {/*  */}
              <div className="relative container mx-auto h-screen  text-center flex flex-col justify-end items-center  text-xl">
                {/* <div
                  className="text-center font-bold text-white drop-shadow"
                  style={{ textShadow: "0px 1px 11px darkslategrey" }}
                >
                  Heavenly Vibes, Hellish Beats.
                </div> */}
                <div className="md:h-16 h-20"></div>
                <div
                  onClick={() => {
                    setIsStart(false);
                    const element = document.getElementById("starting");
                    if (element) {
                      element.scrollIntoView({ behavior: "smooth" });
                    }
                    setIsScrool(false);
                  }}
                  className=" cursor-pointer "
                >
                  <div
                    className="text-center font-bold text-white  bg-[darkslategrey] px-2 py-1 rounded drop-shadow"
                    style={{ textShadow: "0px 1px 11px darkslategrey" }}
                  >
                    Start the experience
                  </div>
                </div>
                <div className="md:h-10 h-28"></div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          {isScrool ? (
            <div
              className=" w-screen h-screen fixed z-50"
              style={{
                backgroundImage:
                  'url("https://epiceventsstatic.s3.eu-west-3.amazonaws.com/animation-eden/web/frame-0003-min.jpg")',
                backgroundPosition: "top",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            >
              <div className="h-screen relative text-center">
                <div className="relative container mx-auto h-screen  text-center flex flex-col justify-end items-center  text-xl">
                  <div className="text-center font-bold text-white justify-center  bg-[darkslategrey] px-2 py-1 rounded drop-shadow flex flex-row item-denter">
                    <div role="status">
                      <img alt="loading" className="w-8 h-8 " src={loading} />
                    </div>
                    {"  "}
                    <div>Loading...</div>
                  </div>
                  <div className="md:h-14 h-28"></div>
                </div>
              </div>
            </div>
          ) : null}
          {/* /darkslategrey */}
          {/* {imageNames.map((imageName, index) => (
            <img
              id={`image-${index}`}
              rel="preload"
              loading="lazy"
              className={`fixed  w-screen h-screen scrollable  transform  md:scale-x-[1]  scale-x-[2.5]  border-none m-o p-0 bg-dark ${
                imageNumber === index + 1 ? "visible" : "hidden"
              }`}
              key={index + 1} // Key should be unique
              src={imageName} // Assuming images are stored in the 'images' folder
              alt={`Frame ${index + 1}`} // Alt text for accessibility
            />
          ))} */}

          {!isScrool && imageNumber > 47 ? (
            <div className=" fixed md:right-10 right-0 top-0 h-screen flex flex-col items-center md:justify-center justify-start z-[99999] ">
              <div className="md:block hidden">
                <MenuItems imageNumber={imageNumber} />
              </div>
              <div className=" md:hidden block w-full z-[99999]">
                <Header imageNumber={imageNumber} />
              </div>
            </div>
          ) : null}
          <div className="absolute  h-screen top-0 z-[90000] drop-shadow scroll-smooth">
            <div id="starting" className="h-screen  text-center ">
              <div className="container mx-auto h-screen  text-center flex flex-col justify-end items-center  text-xl">
                <div className="fixed bottom-12 left-1/2 z-30 transform -translate-x-1/2 mb-10">
                  <div
                    onClick={() => {
                      const element = document.getElementById("reservation");
                      if (element) {
                        element.scrollIntoView({ behavior: "smooth" });
                      }
                    }}
                    className={`animate-bounce border border-[#fff] text-[#fff] p-3 rounded-full cursor-pointer ${
                      imageNumber > 5 ? "hidden" : ""
                    } `}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className=" h-6 w-6 color-[#fff]"
                      fill="#2f4f4f"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19 14l-7 7m0 0l-7-7m7 7V3"
                      />
                    </svg>
                  </div>
                </div>
                <div className="h-14"></div>
              </div>
            </div>
            {/* reservation */}
            <div className=" text-center " id="reservation">
              <div
                className={`container mx-auto h-screen  text-center flex flex-col justify-end  `}
              >
                {/* menu */}
                {/* <MenuItems /> */}
                {/*  */}
                <div
                  className={`w-full text-left px-2 my-2   ${
                    checkPage(imageNumber, "Reservation")
                      ? "hidden  h-screen "
                      : ""
                  }`}
                >
                  <div
                    className="md:w-2/3 md:text-xl text-base drop-shadow px-2 "
                    // style={{ textShadow: "0px 1px 11px darkslategrey" }}
                  >
                    <p className="  inline ">
                      Situé au cœur du quartier de l'Hivernage à Marrakech,
                      l'Eden Nightclub offre une expérience inoubliable pour les
                      noctambules en quête de clubbing. En tant que temple de la
                      vie nocturne dans la ville ocre, cet établissement incarne
                      l'essence même de la fête et de la décadence dans un cadre
                      bohemien et captivant.
                    </p>
                  </div>
                </div>
                {/*  */}
                <div
                  className={`px-2 flex flex-col  items-start  ${
                    checkPage(imageNumber, "Reservation")
                      ? "hidden h-screen "
                      : ""
                  }`}
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://booking.edenclubmarrakech.com/"
                  >
                    <div
                      style={{ textShadow: "0px 1px 11px darkslategrey" }}
                      className="text-center font-bold text-white my-3 bg-[darkslategrey] px-2 py-1 rounded drop-shadow text-xl"
                    >
                      Reservation
                    </div>
                  </a>
                  <div
                    style={{ textShadow: "0px 1px 11px darkslategrey" }}
                    className="text-sm drop-shadow"
                  >
                    Heavenly Vibes, Hellish Beats.
                  </div>
                </div>
                <div className="md:h-20 h-24"></div>
              </div>
            </div>
            <div id="evenements" className="h-screen w-screen text-center ">
              <div className="container mx-auto h-screen   text-center flex flex-col justify-end  ">
                {/* menu */}
                {/* <MenuItems /> */}
                {/*  */}
                <div
                  className={`w-full text-left px-2 my-2 
                    ${
                      checkPage(imageNumber, "Nos evenements")
                        ? " hidden h-screen"
                        : ""
                    }`}
                >
                  <div
                    style={{ textShadow: "0px 1px 11px darkslategrey" }}
                    className="md:w-2/3 md:text-xl text-base drop-shadow  px-2 "
                  >
                    <p className="">
                      Chaque semaine, l'atmosphère électrique est accentuée par
                      la présence de DJ guests de renoms dans ambiance
                      décorative exceptionnelle. Plongez dans un univers
                      bohémien jungle où les murs sont habillés de feuilles
                      d'arbres et de fleurs où chaque détail est pensé pour
                      transporter les clubbers dans un véritable paradis.
                    </p>
                  </div>
                </div>
                {/*  */}
                <div
                  className={`px-2 flex flex-col items-start   ${
                    checkPage(imageNumber, "Nos evenements")
                      ? " hidden h-screen"
                      : ""
                  }`}
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://events.edenclubmarrakech.com/"
                  >
                    <div
                      style={{ textShadow: "0px 1px 11px darkslategrey" }}
                      className="text-center font-bold text-white my-3 bg-[darkslategrey] px-2 py-1 rounded drop-shadow text-xl"
                    >
                      Nos evenements
                    </div>
                  </a>
                  <div
                    style={{ textShadow: "0px 1px 11px darkslategrey" }}
                    className="text-sm drop-shadow"
                  >
                    Where Heaven and Hell Collide in Exotic Revel
                  </div>
                </div>
                <div className="md:h-20 h-24"></div>
              </div>
            </div>
            <div id="cartes" className="h-screen w-screen text-center ">
              <div className="container mx-auto  h-screen  text-center flex flex-col justify-end  ">
                {/* menu */}
                {/* <MenuItems /> */}
                {/*  */}
                <div
                  className={`w-full text-left px-2 my-2   ${
                    checkPage(imageNumber, "Carte") ? " hidden h-screen" : ""
                  }`}
                >
                  <div
                    style={{ textShadow: "0px 1px 11px darkslategrey" }}
                    className="md:w-2/3 md:text-xl text-base drop-shadow  px-2 "
                  >
                    <p className="">
                      En tant que temple de la vie nocturne dans la ville ocre,
                      cet établissement incarne l'essence même de la fête et de
                      la décadence dans un cadre bohemian captivant.
                    </p>
                  </div>
                </div>
                {/*  */}
                <div
                  className={`px-2 flex flex-col items-start   ${
                    checkPage(imageNumber, "Carte") ? " hidden h-screen" : ""
                  }`}
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://booking.edenclubmarrakech.com/carte/"
                  >
                    <div
                      style={{ textShadow: "0px 1px 11px darkslategrey" }}
                      className="text-center font-bold text-white my-3 bg-[darkslategrey] px-2 py-1 rounded drop-shadow  text-xl"
                    >
                      Découvrez la carte
                    </div>
                  </a>
                  <div
                    style={{ textShadow: "0px 1px 11px darkslategrey" }}
                    className="text-sm drop-shadow"
                  >
                    Our secret, your best night
                  </div>
                </div>
                <div className="md:h-20 h-24"></div>
              </div>
            </div>
            <div id="visites" className="h-screen w-screen text-center ">
              <div className="container mx-auto h-screen  text-center flex flex-col justify-center   ">
                {/* menu */}
                {/* <MenuItems /> */}
                {/*  */}
                <div
                  className={`w-full text-left px-2 my-2   ${
                    checkPage(imageNumber, "Visite 360")
                      ? " hidden h-screen"
                      : ""
                  }`}
                >
                  <div
                    style={{ textShadow: "0px 1px 11px darkslategrey" }}
                    className="md:w-2/3 md:text-xl text-base drop-shadow  px-2 "
                  >
                    <p className="">
                      L'Eden Nightclub est également équipé d'un matériel
                      dernier cri au niveau de la sonorisation et de
                      l'éclairage, garantissant une qualité sonore et visuelle
                      incomparable. Les systèmes audio haute performance et les
                      jeux de lumières époustouflants ajoutent une dimension
                      sensorielle à chaque soirée, amplifiant ainsi les
                      sensations et les émotions des clubbers.
                    </p>
                  </div>
                </div>
                {/*  */}
                <div
                  className={`px-2 flex flex-col items-start w-full   ${
                    checkPage(imageNumber, "Visite 360")
                      ? " hidden h-screen"
                      : ""
                  }`}
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://my.matterport.com/show/?m=TaihcLLdYmR&ss=1&sr=-2.89,1.22"
                  >
                    <div
                      style={{ textShadow: "0px 1px 11px darkslategrey" }}
                      className="text-center font-bold text-white my-3 bg-[darkslategrey] px-2 py-1 rounded drop-shadow  text-xl"
                    >
                      Visite 360
                    </div>
                  </a>
                  <div
                    style={{ textShadow: "0px 1px 11px darkslategrey" }}
                    className="text-sm drop-shadow"
                  >
                    Unleash the wild, Embrace the Night
                  </div>
                </div>
                <div className="md:h-20 h-24"></div>
              </div>
            </div>
            <div id="contact-us" className="h-screen w-screen text-center ">
              <div className="container mx-auto h-screen  text-center flex flex-col justify-center   ">
                {/* menu */}
                {/* <MenuItems /> */}
                {/*  */}
                <div
                  className={`w-full text-left px-2 my-2   ${
                    checkPage(imageNumber, "Contactez-nous")
                      ? " hidden h-screen"
                      : ""
                  }`}
                >
                  <div
                    style={{ textShadow: "0px 1px 11px darkslategrey" }}
                    className="md:w-2/3 md:text-xl text-base drop-shadow  px-2 "
                  >
                    <p className="">
                      Contactez-nous pour reserver votre table ou afin plus
                      d’informations concernant nos evenements
                    </p>
                  </div>
                </div>
                {/*  */}
                <div
                  className={`px-2 flex flex-col items-start w-full  ${
                    checkPage(imageNumber, "Contactez-nous")
                      ? " hidden h-screen"
                      : ""
                  }`}
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="http://booking.edenclubmarrakech.com/contact"
                  >
                    <div
                      style={{ textShadow: "0px 1px 11px darkslategrey" }}
                      className="text-center font-bold text-white my-3 bg-[darkslategrey] px-2 py-1 rounded drop-shadow  text-xl"
                    >
                      Contactez-nous
                    </div>
                  </a>
                  <div></div>
                </div>
                <div className="md:h-24 h-16"></div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default HomeScreen;
