import React, { useState } from "react";

import { checkPageMenu } from "../HomeScreen";

function Header(imageNumber) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="w-screen fixed  z-[99999] left-0 top-10 ">
      <div className="container mx-auto md:px-0 px-5">
        <div className="flex justify-between items-center  bg-[darkslategrey]   rounded-full">
          {/* logo */}
          <img alt="" src={"/logo-eden.png"} className=" md:h-20 h-16 ml-10" />
          {/* menu */}

          {/* menu */}
          <div
            className="md:hidden block mx-10 cursor-pointer"
            onClick={() => {
              setIsOpen(true);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-7 h-7 text-white hover:text-[#FF0000]"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25"
              />
            </svg>
          </div>
          <div
            className={`bg-black bg-opacity-70 overflow-hidden h-screen  fixed z-50 top-0 right-0 duration-500 transition-all ${
              isOpen ? "w-full" : "w-0"
            }`}
          >
            <div className="w-screen  flex justify-between px-3 ">
              <svg
                onClick={() => setIsOpen(false)}
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-10 h-10 text-white m-5 cursor-pointer hover:text-[#FF0000]"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
              <a onClick={() => setIsOpen(false)} href="/">
                <img
                  className="h-20"
                  alt="nazai studio"
                  src={"/logo-eden.png"}
                />
              </a>
            </div>
            <div className="flex flex-row items-center md:justify-center justify-start w-full md:px-3 mb-5 md:mt-2 mt-10 ">
              <div className="flex flex-col  md:justify-center justify-start items-center left-0 right-0 md:w-full w-screen ">
                <div
                  onClick={() => {
                    const element = document.getElementById("reservation");
                    setIsOpen(false);
                    if (element) {
                      element.scrollIntoView({ behavior: "smooth" });
                    }
                  }}
                  className={` md:text-xl text-xl font-bold hover:text-[#9e804f]  cursor-pointer mb-2  ${
                    checkPageMenu(imageNumber.imageNumber, "Reservation")
                      ? "text-[#9e804f] "
                      : "text-white"
                  }`}
                  href="/#reservation"
                  style={{
                    textShadow: checkPageMenu(
                      imageNumber.imageNumber,
                      "Reservation"
                    )
                      ? "#9e804f 0px 0px 2px "
                      : "",
                  }}
                >
                  Reservation
                </div>
                {/* <div className="text-sm">|</div> */}
                <div
                  onClick={() => {
                    const element = document.getElementById("evenements");
                    setIsOpen(false);
                    if (element) {
                      element.scrollIntoView({ behavior: "smooth" });
                    }
                  }}
                  className={` md:text-xl text-xl font-bold hover:text-[#9e804f]  cursor-pointer mb-2  ${
                    checkPageMenu(imageNumber.imageNumber, "Nos evenements")
                      ? "text-[#9e804f] "
                      : "text-white"
                  }`}
                  style={{
                    textShadow: checkPageMenu(
                      imageNumber.imageNumber,
                      "Nos evenements"
                    )
                      ? "#9e804f 0px 0px 2px "
                      : "",
                  }}
                  href="/#evenements"
                >
                  Nos evenements
                </div>
                {/* <div className="text-sm">|</div> */}
                <div
                  onClick={() => {
                    const element = document.getElementById("cartes");
                    setIsOpen(false);
                    if (element) {
                      element.scrollIntoView({ behavior: "smooth" });
                    }
                  }}
                  className={` md:text-xl text-xl font-bold hover:text-[#9e804f]  cursor-pointer mb-2  ${
                    checkPageMenu(imageNumber.imageNumber, "Carte")
                      ? "text-[#9e804f] "
                      : "text-white"
                  }`}
                  style={{
                    textShadow: checkPageMenu(imageNumber.imageNumber, "Carte")
                      ? "#9e804f 0px 0px 2px "
                      : "",
                  }}
                  href="/#cartes"
                >
                  Carte
                </div>
                {/* <div className="text-sm">|</div> */}
                <div
                  onClick={() => {
                    const element = document.getElementById("visites");
                    setIsOpen(false);
                    if (element) {
                      element.scrollIntoView({ behavior: "smooth" });
                    }
                  }}
                  className={` md:text-xl text-xl font-bold hover:text-[#9e804f]  cursor-pointer mb-2  ${
                    checkPageMenu(imageNumber.imageNumber, "Visite 360")
                      ? "text-[#9e804f] "
                      : "text-white"
                  }`}
                  style={{
                    textShadow: checkPageMenu(
                      imageNumber.imageNumber,
                      "Visite 360"
                    )
                      ? "#9e804f 0px 0px 2px "
                      : "",
                  }}
                  href="/#visites"
                >
                  Visite 360
                </div>
                {/* <div className="text-sm">|</div> */}
                <div
                  onClick={() => {
                    const element = document.getElementById("contact-us");
                    setIsOpen(false);
                    if (element) {
                      element.scrollIntoView({ behavior: "smooth" });
                    }
                  }}
                  className={` md:text-xl text-xl font-bold hover:text-[#9e804f]  cursor-pointer mb-2  ${
                    checkPageMenu(imageNumber.imageNumber, "Contactez-nous")
                      ? "text-[#9e804f] "
                      : "text-white"
                  }`}
                  href="/#contact-us"
                  style={{
                    textShadow: checkPageMenu(
                      imageNumber.imageNumber,
                      "Contactez-nous"
                    )
                      ? "#9e804f 0px 0px 1px "
                      : "",
                  }}
                >
                  Contactez-nous
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
